import "./about-spice.css";

const WhereWeWorkComponent = () => {
    return (
        <>
            <div class="content-about">
                <h4>Where We Work</h4>
                <p class="intro">
                    SPICE is a global program active in over 20 countries in Africa, Europe, South America and South-East Asia.
                </p>

                <h5>
                    Projects (coming soon)
                </h5>
                <h5>
                    Missions (coming soon)
                </h5>

            </div>
        </>
    )
}

export default WhereWeWorkComponent;