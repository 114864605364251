import React from 'react';
import { Box, TextField, MenuItem, Grid, Typography, Button } from '@mui/material';

const fieldsOfExpertise = [
  'Infectious diseases management',
  'Clinical microbiology',
  'Diagnostic stewardship and laboratory services',
  'Infection prevention and control',
  'Antimicrobial Stewardship',
];

const GenericForm = () => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        maxWidth: '600px', // Adjust the width as needed
        margin: '20px auto',
        padding: '16px', // Add some padding
        border: '1px solid #ccc', // Optional: add a border for better visual separation
        borderRadius: '8px', // Optional: round the corners of the border
      }}
    >
      <Typography variant="h5" mb={'25px'} color={'primary'} align="center">
        Apply
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Name"
            id="name"
            defaultValue=""
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Surname"
            id="surname"
            defaultValue=""
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            id="email"
            type="email"
            defaultValue=""
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone"
            id="phone"
            type="tel"
            defaultValue=""
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="field-of-expertise"
            select
            label="Field of Expertise"
            defaultValue=""
            size="small"
            fullWidth
          >
            {fieldsOfExpertise.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" fullWidth disabled>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenericForm;
