import GenericLoremIpsum from "../shared/generic-loremipsum"

const WhatIsAmsTrainerComponent = () => {
    return (
        <>
            <div>
                <h4>What is AMS Trainer</h4>
                <GenericLoremIpsum />
            </div>
        </>
    )
}

export default WhatIsAmsTrainerComponent;