import AboutSpicePage from "../../pages/about/about-spice";
import ContactUsPage from "../../pages/about/contact-us";
import BecomeExpertApplyPage from "../../pages/become-expert/become-expert-apply";
import BecomeExpertTermsAndConditionsPage from "../../pages/become-expert/become-expert-terms-and-conditions";
import ExpertStoriesPage from "../../pages/become-expert/expert-stories";
import WhatIsSpiceExpertPage from "../../pages/become-expert/what-is-spice-expert";
import BecomeTrainerApplyPage from "../../pages/become-trainer/become-trainer-apply";
import OurTrainersPage from "../../pages/become-trainer/our-trainers";
import WhatIsAMSTrainerPage from "../../pages/become-trainer/what-is-ams-trainer";
import LandingPage from "../../pages/landing-page";
import NewsPage from "../../pages/news-page";
import PartnershipsPage from "../../pages/partnerships-page";
import ESCMIDPage from "../../pages/partnerships/ESCMID";
import ICARSPage from "../../pages/partnerships/ICARS";
import ISACPage from "../../pages/partnerships/ISAC";
import GetInvolvedPartnershipsPage from "../../pages/partnerships/get-involved";
import OthersPage from "../../pages/partnerships/others";
import AreasOfSupportPage from "../../pages/request-support/areas-of-support";
import RequestSupportApplyPage from "../../pages/request-support/request-support-apply";
import RequestSupportTermsAndConditionsPage from "../../pages/request-support/request-support-terms-and-conditions";
import TypesOfSupportPage from "../../pages/request-support/types-of-support";
import GetInvolvedPage from "../../pages/about/get-involved";
import WhereWeWorkPage from "../../pages/about/where-we-work";
import { AppRoutes } from "./app-routes";

export const CustomRoutes = [
    {
        path: AppRoutes.BASE,
        element: <LandingPage />
    },
    // {
    //     path: AppRoutes.News,
    //     element: <NewsPage />
    // },
    {
        path: AppRoutes.WhereWeWork,
        element: <WhereWeWorkPage />
    },
    {
        path: AppRoutes.AboutSpice,
        element: <AboutSpicePage />
    },
    {
        path: AppRoutes.RequestSupportApply,
        element: <RequestSupportApplyPage />
    },
    {
        path: AppRoutes.GetInvolvedWhatWeDo,
        element: <GetInvolvedPage />
    },
    {
        path: AppRoutes.WhatIsSpiceExpert,
        element: <WhatIsSpiceExpertPage />
    },
    {
        path: AppRoutes.ExpertStories,
        element: <ExpertStoriesPage />
    },
    {
        path: AppRoutes.ExpertApply,
        element: <BecomeExpertApplyPage />
    },
    {
        path: AppRoutes.TermsAndConditionsExpert,
        element: <BecomeExpertTermsAndConditionsPage />
    },
    {
        path: AppRoutes.WhatIsAMSTrainer,
        element: <WhatIsAMSTrainerPage />
    },
    {
        path: AppRoutes.OurTrainers,
        element: <OurTrainersPage />
    },
    {
        path: AppRoutes.TrainerApply,
        element: <BecomeTrainerApplyPage />
    },
    {
        path: AppRoutes.AreasOfSupport,
        element: <AreasOfSupportPage />
    },
    {
        path: AppRoutes.TypesOfSupport,
        element: <TypesOfSupportPage />
    },
    {
        path: AppRoutes.TermsAndConditionsRequestSupport,
        element: <RequestSupportTermsAndConditionsPage />
    },
    {
        path: AppRoutes.ContactUs,
        element: <ContactUsPage />
    },
    {
        path: AppRoutes.Partnerships,
        element: <PartnershipsPage />
    }
    // {
    //     path: AppRoutes.ESCMID,
    //     element: <ESCMIDPage />
    // },
    // {
    //     path: AppRoutes.GetInvolvedPartnerships,
    //     element: <GetInvolvedPartnershipsPage />
    // },
    // {
    //     path: AppRoutes.ICARS,
    //     element: <ICARSPage />
    // },
    // {
    //     path: AppRoutes.ISAC,
    //     element: <ISACPage />
    // },
    // {
    //     path: AppRoutes.Others,
    //     element: <OthersPage />
    // }
]