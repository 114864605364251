export const requiredHospitalsApplyFormProps = [
    'name',
    'address',
    'city',
    'country',
    'legal_representative_authorized_person',
    'legal_representative_authorized_person_position',
    'contact_person',
    'contact_person_position',
    'phone_number',
    'email',
    'about_the_hospital',
    'institution_level_id',
    'institution_type_id',
    'field_of_expertise_ids',
    'requested_support_description',
    'letter_of_commitment_base64',
    // 'comments_and_additional_info',
    'consent',
    'terms_and_conditions',
    'information_accuracy_confirmation'
];



export const hospitalsApplyFormProps = {
    name: '',
    address: '',
    city: '',
    country: '',
    legal_representative_authorized_person: '',
    legal_representative_authorized_person_position: '',
    contact_person: '',
    contact_person_position: '',
    phone_number: '',
    email: '',
    about_the_hospital: '',
    institution_level_id: null,
    institution_level_other: null,
    institution_type_id: null,
    institution_type_other: null,
    field_of_expertise_ids: null,
    field_of_expertise_other: null,
    requested_support_description: '',
    letter_of_commitment_base64: null,
    comments_and_additional_info: '',
    consent: false,
    terms_and_conditions: false,
    information_accuracy_confirmation: false
}