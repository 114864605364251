import "./about-spice.css";

const AboutSpiceComponent = () => {
    return (
        <>
            <div class="content-about">
                <h4>About SPICE</h4>
                <p class="intro">
                    As much as infectious disease problems are universal, every healthcare setting is different and has particular human and infrastructural capacities and organizational culture that is put in use to serve their specific population. Therefore, the challenge – and at the same time the value – comes from this diversity, which the SPICE program addresses through assigning expert support after a careful matching of institutions’ needs with experts’ experience.
                </p>
                <h4>
                    The SPICE program is providing expertise in:
                </h4>
                <ul>
                    <li>Infectious disease management</li>
                    <li>Microbiology and diagnostics</li>
                    <li>Infection prevention and control</li>
                    <li>Antimicrobial Stewardship</li>
                    <li>Implementation science and behavior change</li>
                </ul>
                {/* <h4>Governance</h4>
                <h4>Secretariat</h4>
                <h4>Projects</h4>
                <h4>Missions</h4> */}

            </div>
        </>
    )
}

export default AboutSpiceComponent;