import './become-expert-terms-and-conditions.css';

const BecomeExpertTermsAndConditionsComponent = () => {
    return (
        <>
            <div class="content-terms-ex">
                <h4>SPICE Expert - Terms and Conditions</h4>
                <p>The participation of expert consultants in the SPICE implementation support program is governed by Terms of Reference available <a href="">here</a>. </p>
                <p>Following a successful matching, the host institution and the SPICE expert sign a confidentiality agreement and other necessary documents to enable implementation of the expert support, in line with the rules of the host institution.</p>
                <p>During the implementation support period, experts are required to submit a short mid-term and final reports of their activities and accomplishments with the support, as well as any observations and recommendations they have for the future.</p>
                <p>Following the provided expert, the host institution is also asked to provide a feedback report on the collaboration and support received from the SPICE expert.</p>
            </div>
        </>
    )
}

export default BecomeExpertTermsAndConditionsComponent;